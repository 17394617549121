import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  position: fixed;
  background-color: #2F2F2F;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.4s, transform 0.4s;
  pointer-events: none;
  transform: translateX(100%);
  &.open {
    pointer-events: all;
    opacity: 1;
    transform: none;
  }
`

const Inner = styled.div`
  padding: 6rem 4rem 1rem 2rem;
  display: flex;
  flex-direction: column;
`

const List = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
`

const Label = styled.a`
  display: block;
  font-size: 0.8125rem;
  padding: 1.25rem 0;
  @media (max-width: 40rem){
    font-size: 0.75rem;
  }
`

const InnerList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;
  margin-top: -0.5rem;
`

const InnerListItem = styled.a`
  display: block;
  font-size: 0.8125rem;
  color: #BEBEBE;
  padding: 0.5rem 0;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
  }
  &::before {
    content: '・';
  }
`

type MenuProps = {
  open: boolean
}
export const Menu: FunctionComponent<MenuProps> = ({open}) => {
  return <Outer className={classNames({open})}>
    <Inner>
      <List>
        <Item>
          <Label href="/shop/information_categories/topics">
            TOPICS
          </Label>
        </Item>
        <Item>
          <Label href="/shop/products">
            PRODUCTS
          </Label>
          <InnerList>
            <InnerListItem href="/shop/products/ZC001">
              APROS iO
            </InnerListItem>
            <InnerListItem href="/shop/products/ZZ001">
              ダニとり酵母
            </InnerListItem>
            <InnerListItem href="/shop/products/ZH001">
              シボサポ
            </InnerListItem>
            <InnerListItem href="/shop/products/ZH010">
              メグラナイト
            </InnerListItem>
          </InnerList>
        </Item>
        <Item>
          <Label href="/shop/faq">
            FAQ
          </Label>
        </Item>
        <Item>
          <Label href="/shop/contact">
            CONTACT
          </Label>
        </Item>
      </List>
    </Inner>
  </Outer>
}