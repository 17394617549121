import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Menu } from './menu'
import classNames from 'classnames'
import SvgBag from '../generated/svg/bag'
import SvgPerson from '../generated/svg/person'
import SvgLogo from '../generated/svg/logo'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: opacity 0.4s, transform 0.4s;
  pointer-events: none;
  &.hide {
    transform: translateY(-100%);
    opacity: 0;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: stretch;
  padding: 2.5rem;
  gap: 1.5rem;
  @media (max-width: 40rem){
    padding: 1.75rem;
  }
`

const Logo = styled.a`
  margin-right: auto;
  display: flex;
  align-items: center;
  pointer-events: initial;
  svg {
    height: 1.5rem;
    width: auto;
    display: block;
  }
`

const Navigator = styled.div`
  display: flex;
  gap: 1rem;
  align-items: stretch;
  pointer-events: initial;
  @media (max-width: 40rem){
    display: none;
  }
`

const NavigatorItem = styled.a`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: white;
    display: block;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 0.6s;
  }
  &:hover &::after {
    transform: none;
    transform-origin: 0 0;
  }
  @media (max-width: 40rem){
    font-size: 1.375rem;
  }
`

const Label1 = styled.div`
  transform: none;
  transition: transform 0.6s, opacity 0.6s;
  ${NavigatorItem}:hover & {
    transform: translateY(90%) scaleY(0);
    opacity: 0;
  }
`

const Label2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-140%) scaleY(0);
  transition: transform 0.6s, opacity 0.6s;
  opacity: 0;
  ${NavigatorItem}:hover & {
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
  }
`

const ChildOuter = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: max-content;
  opacity: 0;
  transform: translateY(-1.5rem);
  transition: opacity 0.4s, transform 0.4s;
  pointer-events: none;
  ${NavigatorItem}:hover & {
    opacity: 1;
    transform: none;
    pointer-events: all;
  }
`

const ChildItem = styled.a`
  display: block;
  padding: 0.5rem 0.5rem;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: stretch;
  pointer-events: all;
`

const Button = styled.a`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 200;
  transition: color 0.4s;
  .open & {
    color: white
  }
  svg {
    display: block;
    height: 1.625rem;
    width: auto;
    @media (max-width: 40rem){
      height: 1.25rem;
      width: auto;
    }
  }
`

const MenuButton = styled.div`
  display: none;
  position: relative;
  pointer-events: fill;
  width: 1.75rem;
  z-index: 200;
  cursor: pointer;
  transition: color 0.4s;
  .open & {
    color: white
  }
  @media (max-width: 40rem){
    display: initial;
  }
  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1.75rem;
    height: 1px;
    background-color: currentColor;
    top: 50%;
    left: 50%;
    translate: -50%;
    transition: transform 0.4s;
  }
  &::before {
    transform: translateY(-0.25rem);
  }
  &.open::before {
    transform: rotate(-20deg);
  }
  &::after {
    transform: translateY(0.25rem);
  }
  &.open::after {
    transform: rotate(20deg);
  }
`


type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [hide, setHide] = useState(false)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    let lastScrollY = window.scrollY
    const listener = () => {
      const scrollY = window.scrollY
      if(scrollY === 0 || scrollY < lastScrollY){
        setHide(false)
      } else {
        setHide(true)
      }
      lastScrollY = scrollY
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  setOpen
  return <>
    <Outer className={classNames({hide: !open && hide, open})}>
      <Inner>
        <Logo href="/">
          <SvgLogo />
        </Logo>
        <Navigator>
          <NavigatorItem href="/shop/information_categories/topics">
            <Label1>TOPICS</Label1>
            <Label2>TOPICS</Label2>
          </NavigatorItem>
          <NavigatorItem>
            <Label1>PRODUCTS</Label1>
            <Label2>PRODUCTS</Label2>
            <ChildOuter>
              <ChildItem href="/shop/products/ZC001">APROS iO</ChildItem>
              <ChildItem href="/shop/products/ZZ001">ダニとり酵母</ChildItem>
              <ChildItem href="/shop/products/ZH001">シボサポ</ChildItem>
              <ChildItem href="/shop/products/ZH010">メグラナイト</ChildItem>
            </ChildOuter>
          </NavigatorItem>
          <NavigatorItem href="/shop/information_categories/news">
            <Label1>INFORMATION</Label1>
            <Label2>INFORMATION</Label2>
          </NavigatorItem>
          <NavigatorItem href="/shop/faq">
            <Label1>FAQ</Label1>
            <Label2>FAQ</Label2>
          </NavigatorItem>
          <NavigatorItem href="/shop/contact">
            <Label1>CONTACT</Label1>
            <Label2>CONTACT</Label2>
          </NavigatorItem>
        </Navigator>
        <ButtonContainer>
          <Button href="/shop/cart">
            <SvgBag />
          </Button>
          <Button href="/shop/customer">
            <SvgPerson />
          </Button>
        </ButtonContainer>
        <MenuButton className={classNames({open})} onClick={() => setOpen(v => !v)} />
        <Menu open={open} />
      </Inner>
    </Outer>
  </>
}